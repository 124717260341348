*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --font-size: 16px;
}

.privacy{
  font-size: 12px;
  line-height: 21px;
  max-width: 270px;
}
body {
  margin: 0;
  font-size: var(--font-size);
}

img {
  max-width: 100%;
  display: block;
}
